import {MenuOption} from "../../shared/models/menu-option";

export const MAX_EXPERTS_COUNT_PER_PAGE = 20;
export const MAX_TOPICS_COUNT_PER_PAGE = 100;
export const defaultLanguage: MenuOption = new MenuOption(undefined, 'All Languages');
export const defaultTopic: MenuOption = new MenuOption(undefined, 'All Expertises');
export const closeIcon = 'img/exit-icon.svg';

export interface ExpertModel {
    createdAt: string,
    description: string,
    expertId: string,
    fullName: string,
    imgAsset: { url: string },
    lang: string[],
    profileImageUrl: string,
    profilePage: { id: string, url: string },
    role: string,
    status: string,
    tags: string[],
    expertises: MenuOption[],
    userId: string,
    profileColor ?: string,
    moreLangs? : string;
    moreExpertise? : string;
    showAllExp? : boolean;
    showAllLang? : boolean;
}

export const TopicsColor = [
    '#00ABFE14',
    '#00D09614',
    '#AA58EE14',
    '#FF4D5B14'
];

export const ProfileColor = [
    'linear-gradient(90deg, #00EA3B 0%, #00D096 100%)',
    'linear-gradient(270deg, #28B3F7 0.65%, #506AFF 99.35%)',
    'linear-gradient(225deg, #FF75BE 0%, #CD33A3 100%)',
    'linear-gradient(203.96deg, #A35EEF 3.69%, #2F51C7 84.62%)',
    'linear-gradient(270deg, #F79F28 0.65%, #FF5350 99.35%)'
]

export function getExpertiseName(expertise) {
    switch (expertise) {
        case 'onCameraSkills':
            return 'On Camera Skills';
        case 'videoMarketing':
            return 'Video Marketing'
        case 'realEstateMarketing':
            return 'Real Estate Marketing'
        case 'videoEditing':
            return 'Video Editing'
        case 'smallBusinessCouching':
            return 'Small Business Couching'
        default:
            return expertise
    }

}


export function getLangsName(lang) {
    switch (lang) {
        case 'en':
            return 'English';
        case 'fr':
            return 'French'
        case 'pt':
            return 'Portuguese'
        case 'es':
            return 'Spanish'
        case 'ru':
            return 'Russian'
        case 'he':
            return 'Hebrew'
        default:
            return lang
    }

}